import React, { useEffect, useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Button } from "@material-ui/core";

import "./Intro.scss";

export const Intro = () => {
  const [captchaValue, setCaptchaValue] = useState<string | null>("");

  const handleVerification = (token: string) => {
    // Handle the verification token here
    setCaptchaValue(token);
  };
  useEffect(() => {
    if (captchaValue === "") {
      return;
    }

    window.location.href = "https://challenge-captcha-029312-buildo-01.vercel.app/";
  }, [captchaValue]);

  return (
    <div className="Intro__new">
      <div className="Intro__new__img">
        <img src={require("../../assets/images/news.jpg")} alt="img" />
      </div>
      <h2>Help us confirm it's you</h2>
      <HCaptcha
        sitekey="78b88888-8296-435a-9d2c-ca842a76e40c"
        onVerify={handleVerification}
      />
      <p>
        This helps us to combat harmful conduct, detect and prevent spam and
        maintain the integrity of our Products. <br />
        <br />
        We've used Google's reCAPTCHA Enterprise product to provide this
        security check. Your use of reCAPTCHA Enterprise is subject to Google's
        Privacy Policy and Terms of Use.
        <br />
        <br />
        reCAPTCHA Enterprise collects hardware and software information such as
        device and application data, and sends it to Google to provide,
        maintain, and improve reCAPTCHA Enterprise and for general security
        purposes. This information is not used by Google for personalized
        advertising.
      </p>
      <Button
        type="submit"
        style={{ alignSelf: "flex-end" }}
        variant="contained"
        disabled={captchaValue === ""}
      >
        <span>Continue</span>
      </Button>
    </div>
  );
};
